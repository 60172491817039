.btn-eyesee {
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#69c), color-stop(45%, #969), color-stop(90%, #f33));
  background-image: -webkit-linear-gradient(top left, #69c 0, #969 45%, #f33 90%);
  background-image: linear-gradient(to bottom right, #69c 0, #969 45%, #f33 90%);
  border: 1px solid var(--eyesee-purple) !important;
}

.btn-eyesee:hover {
  color: var(--eyesee-purple);
  background-color: #fff;
  border: 1px solid var(--eyesee-purple) !important;
  background-image: none;
}

.btn-eyesee-linear,
.btn-eyesee-linear.transparent-hover-bg:hover {
  background-image: linear-gradient(to top right, rgba(102, 153, 204, 1) 0%, rgba(153, 102, 153, 1) 45%, rgba(255, 51, 51, 1) 90%);
  background-color: transparent;
  border: 1px solid var(--eyesee-purple) !important;
}

.btn-eyesee-linear:hover {
  color: var(--eyesee-purple);
  background-color: #fff;
  background-image: none;
}

.btn-eyesee-linear span {
  color: #fff;
}

.btn-eyesee-linear:hover span {
  color: var(--eyesee-purple);
}

.btn-linear {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 5px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  padding: 0.4em 1.5em;
  border-radius: 5px;
}

.btn-linear.btn- span {
  color: inherit;
  border-color: inherit;
}

.btn-outlined {
  color: #999;
  background-color: #fff;
  border: 1px solid #999 !important;
}

.btn-outlined:hover {
  background: #999;
  color: #fff;
}

.btn-outlined:hover span {
  color: #fff;
}

.wp-btn {
  -webkit-transition: all 0s !important;
  transition: all 0s !important;
  outline: none;
  line-height: 1.3;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  box-shadow: none;
  border-radius: 20px;
  font-size: 15px;
}

.btn-round {
  width: 10vh;
  border-radius: 100%;
  padding: 0;
}

.btn-round:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
}

.btn-round span {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
}

.btn-round-sm {
  width: 8vh;
}

@media (max-height: 900px) {
  .btn-round-sm {
    width: 10vh;
  }

  .lang-de .wp-btn,
  .lang-fr .wp-btn span {
    font-size: 11px;
  }

  .lang-fr .wp-btn.btn-round {
    font-size: 13px;
  }
}

@media (max-height: 800px) {
  .btn-round-sm {
    width: 10vh;
  }

  .wp-btn.btn-round-sm span {
    font-size: 12px;
  }

  .wp-btn {
    font-size: 12px;
  }

  .lang-fr .wp-btn span,
  .lang-de .wp-btn span,
  .lang-ru .wp-btn span {
    font-size: 10px;
  }
}

@media (max-height: 530px) {
  .wp-btn {
    font-size: 10px;
  }
}