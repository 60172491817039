* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

body {
  overflow-y: hidden;
}

:root {
  --eyesee-blue: #6699cc;
  --eyesee-purple: #996699;
  --eyesee-red: #ff3333;
  --eyesee-grey: #999999;
  --eyesee-black: #333333;
}

html {
  background: var(--eyesee-black);
  color: var(--eyesee-black);
}

.text-center {
  text-align: center;
}

.app {
  background: var(--eyesee-black);
}

video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom right, #69c 0, #969 45%, #f33 90%);
  border-radius: 10px;
} */

#converted {
  visibility:'hidden';
  width: 0;
  height: 0;
}

.selection-disabled {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
